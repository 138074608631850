import { computed, ref, watch } from '@vue/composition-api';
import store from '@/store';
import { useRouter } from '@core/utils/utils';
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default function usePaymentResult() {
  const { route } = useRouter();
  const routeQuery = computed(() => route.value.query);
  const countDown = ref(10);
  const validate = ref(-1);
  const { t } = useI18nUtils();
  const startCountdown = () => {
    if (countDown.value > 0) {
      setTimeout(() => {
        countDown.value -= 1;
      }, 1000);
    } else {
      // window.location.href = "/";
    }
  };

  const validateResult = () => {
    store.dispatch('plan/validateResult', routeQuery.value).then(() => {
      validate.value = 1;
      startCountdown();
    }).catch(() => {
      validate.value = 0;
      startCountdown();
    });
  };

  watch(countDown, val => {
    if (val > 0) {
      setTimeout(() => {
        countDown.value -= 1;
      }, 1000);
    } else {
      window.location.href = "/";
    }
  });

  return {
    validate,
    countDown,
    startCountdown,
    validateResult,
    t,
  };
}
